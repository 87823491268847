 <template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row id="more" class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Cyberbullying 
          </h2>
          <p class="hashText">- az offline bántalmazás tükre</p>
        </b-col>
      </b-row>

      <b-row class="maintText__row">
        <b-col cols="12" lg="6" class="side-gap order-0">
          <div class="maintText__txt-content">
            <p class="text">
              Az internetet sokan korunk kerekeként, annak legnagyobb felfedezéseként tartják számon. Lassan egy egész generáció nő fel úgy, hogy beleszületett ebbe a digitális világba, amiben ugyanúgy éli napjait, mint a valóságban. Ez a fajta tükörvilág azonban nem áll meg a pozitívumok átvitelénél: az offline világban tapasztalható zaklatás és bántalmazás („bullying”) sajátos formája ütötte fel a fejét a kibertérben.<br>
              A cyberbullying az olyan internetes bántalmazást takarja, ami ötvözi a való világban is tapasztalható, egy bizonyos személyt vagy csoportot célzó, ismétlődő és kifejezetten bántó cselekvéseket. De mik ezek a cselekvések pontosan?</p>
              <p class="text pt-5 mt-5">Az internetes bántalmazás főbb formái: 
            </p>
              
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap order-4 order-lg-1">
            <img src="@/assets/images/bgr/cyber2.jpg" class="w-100 margin-top-sm-44 pb-3"/>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Lángháború:</b> Lángol a chat, szinte tapintható a feszültség: a cyberbullying ezen formája a provokáció, vitagerjesztés által bánt. Célja, hogy idegesítse, vagy éppen megalázza az illetőt vagy csoportot, akivel vagy nincs egy véleményen, vagy egyszerűen kinézte magának, mint áldozatot. A bántalmazó e-mailen, sms-ben, chat közben erőszakos, durva, becsmérlő szövegeket küld a másik személynek vagy csoportnak.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Befeketítés:</b> Sértő, igaztalan vagy kegyetlen szövegek, képek, videók posztolása, hogy megalázza, tönkretegye az áldozat valamilyen kapcsolatát, vagy kikezdje az önbecsülését. Hasonlóan az offline világ pletykáihoz, ereje a gyors terjedésében rejlik, ami az interneten keresztül pillanatok alatt eljut minden célszemélyhez. Külön veszélye, hogy zárt csoportokban terjedve az áldozatnak akár fogalma sincs róla, milyen képek, videók, információk terjengenek róla, ám ha mégis, szinte lehetetlen eltávolítania ezeket.  </p>
                </li>
              </ul>
            </p>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap order-lg-3 order-2">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Kizárás:</b> Ugyan az online térben a szó szoros értelmében nem lehet hátat fordítani, ujjal mutogatni, vagy éppen látványosan ignorálni más embereket, a kirekesztés más formái ugyanúgy élnek. Ki lehet például zárni az áldozatot egy online csoportból, például egy játékcsapatból vagy egy platform zárt közösségéből.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Személyiséglopás:</b> Fényképeket, dokumentumokat, esetleg bankkártya-adatokat csak el lehet lopni, de személyiséget is? Az ilyen típusú cyberbullying esetén a bántalmazó nem is a tulajdonságainkat lopja el, hanem az annak kifejezésére használt közösségi média felületeinket. Ennek célja lehet sértő üzenetek, posztok küldése az áldozat számára fontos embereknek, vagy éppen nyilvánosan lejáratni internetes ismerősei előtt úgy, hogy mások azt hiszik, továbbra is az áldozat kezeli a saját felületét. Ez lehetséges a profil feltörésével vagy éppen új profil létrehozásával az áldozat nevében.  </p>
                </li>
              </ul>
            </p>
        </b-col>
        <b-col cols="12" class="side-gap order-lg-4 order-3">
            <div>
                <p class="text">
                A cyberbullying a típusain kívül egy fontos dologban is eltér. Míg az offline bántalmazás egy térben, közösségben történik meg, az online bántalmazásból fizikai értelemben nincs kiút, nem lehet elmenekülni, otthagyni a bántalmazót a tett helyszínén. <br>
                A legfőbb megoldás a prevenció és a bántalmazás jelentése, de leginkább az, ha az interneten is nyitott szemmel járunk, és elejét vesszük az agresszió bármely formájának. 
                </p>
            </div>
        </b-col>
      </b-row>
      </b-container>
      <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Cyberbullying - az offline bántalmazás tükre", 
        desc: "Az internetet sokan korunk kerekeként, annak legnagyobb felfedezéseként tartják számon.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Cyberbullying - az offline bántalmazás tükre",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Az internetet sokan korunk kerekeként, annak legnagyobb felfedezéseként tartják számon."        
    })
  }
}
</script>